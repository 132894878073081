<template>
  <div class="treaty">
    <div class="topImg">
      <img src="./img/treaty.png" alt="" />
    </div>
    <div class="body">
      <div class="body-center">
        <div class="body-center-title">
          <h2>和丰社区公约</h2>
          <h3>（2021年2月25日经社区居民会议表决通过）</h3>
        </div>
        <div class="body-c">
          <div class="h-title">第一章 总 则</div>
          <div class="item">
            <div class="item-t">第一条：</div>
            <div class="item-b">
              为全面深化基层民主法治建设和基层政权建设，推进解决社区治理,根据《中华人民共和国宪法》、《中华人民共和国城市居民委员会组织法》和有关法律、法规、政策制定本公约。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二条：</div>
            <div class="item-b">
              坚持党的领导，坚持法治、德治、自治相结合，培育和践行社会主义核心价值观和当代鄞州人共同价值观，倡导爱国敬业、诚信友爱、崇德向善，传承优良传统文化，树立良好社会风尚，努力把社区建设成邻里和睦、家园和美、平安和谐的生活共同体。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第三条：</div>
            <div class="item-b">
              居住在本社区的人员应当遵守本公约。党员居民要带头遵守本公约，充分发挥先锋模范作用。
            </div>
          </div>
          <div class="h-title">第二章 婚姻家庭</div>
          <div class="item">
            <div class="item-t">第四条：</div>
            <div class="item-b">
              遵循婚姻自由、男女平等、尊老爱幼原则，共建团结和睦的家庭关系。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第五条：</div>
            <div class="item-b">
              夫妻双方在家庭中地位平等，应互尊互谅，共同承担家庭事务，共同管理家庭财产，禁止家庭暴力。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第六条：</div>
            <div class="item-b">遵守计划生育政策，提倡优生优育。</div>
          </div>
          <div class="item">
            <div class="item-t">第七条：</div>
            <div class="item-b">
              子女应尽赡养老人义务，关心老人、尊重老人；外出子女要经常回家看望父母，父母应尽抚养未成年人子女和无生活能力子女的义务，不虐待儿童。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第八条：</div>
            <div class="item-b">
              倡导立家规、传家训、树家风；倡导文明新风，喜事新办，丧事简办、节庆俭办，不铺张浪费，不盲目跟风攀比；不搞封建迷信活动，不搞宗派活动。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第九条：</div>
            <div class="item-b">
              倡导良好文明习惯。讲卫生、护环境、有礼貌、重信用、献爱心、讲奉献。文明旅游、文明用餐、文明上网、文明交往，共同营造良好社会风尚。
            </div>
          </div>
          <div class="h-title">第三章 邻里关系</div>
          <div class="item">
            <div class="item-t">第十条：</div>
            <div class="item-b">
              坚持互尊互爱、互帮互助、互让互谅，共建和睦融洽的邻里关系。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第十一条：</div>
            <div class="item-b">
              邻居见面主动问好；邻居外出走亲访友、务工经商，要帮助照看，遇到异常情况及时告知物业管理人员或相关人员。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第十二条：</div>
            <div class="item-b">
              孩子之间发生冲突，家长首先教导自家孩子；注意呵护孩子自尊，避免在公共场合责罚孩子。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第十三条：</div>
            <div class="item-b">
              清晨和夜晚，主动降低室内电视、音响的音量；晚归人员进楼道轻脚步、轻说话、轻关门，防止影响他人正常休息；不往窗外抛撒物品，阳台晾晒浇灌防止滴水。
            </div>
          </div>
          <div class="h-title">第四章 公共秩序</div>
          <div class="item">
            <div class="item-t">第十四条：</div>
            <div class="item-b">
              自觉维护社区秩序，积极参与社区公共活动，共建良好的公共秩序。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第十五条：</div>
            <div class="item-b">
              提倡文明出行，礼让斑马线，文明过马路，养成良好出行习惯。开车进入住宅区要减速慢行、不鸣笛，规范停放汽车、电瓶车、自行车，不将车辆停放在楼道口、绿化带、消防通道。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第十六条：</div>
            <div class="item-b">
              文明饲养宠物，不饲养大型非温和犬类；文明遛狗，注意牵绳，及时清理犬类排泄物；及时为宠物办理合法证件，定期注射疫苗；不在公共场所放养家禽。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第十七条：</div>
            <div class="item-b">
              办理婚丧乔迁等活动，不妨碍社区公共秩序和公共环境。禁燃区内严禁燃放烟花爆竹。
            </div>
          </div>
          <div class="h-title">第五章 美丽家园</div>
          <div class="item">
            <div class="item-t">第十八条：</div>
            <div class="item-b">
              积极配合参与“五水共治”、“三改一拆”、垃圾分类等中心工作，共建美丽家园、共创美好生活。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第十九条：</div>
            <div class="item-b">
              共同遵守社区整体规划，不搞违章搭建；文明装修，不得改动承重墙，未经批准不得改变房屋结构。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二十条：</div>
            <div class="item-b">
              共同维护社区绿化，不在草坪、绿化带上种植蔬菜，不得私自破坏绿化改作停车位，不得在社区内放养家禽。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二十一条：</div>
            <div class="item-b">
              共同维护社区整洁，不在楼道堆放物品，防止给他人造成不便；实行垃圾源头分类、减量处理、定点投放；保持公共场所环境整洁，自觉带走废弃物品。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二十二条：</div>
            <div class="item-b">
              提倡绿色生活，倡导使用环保袋或竹篮购物买菜，尽量避免白色污染；提倡使用自行车或公共交通出行。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二十三条：</div>
            <div class="item-b">
              家庭用餐、集体用餐养成使用“公筷公勺”和消毒餐具的良好习惯。践行文明餐桌，拒绝食用野味。坚持“光盘行动”,杜绝“舌尖上的浪费”。
            </div>
          </div>
          <div class="h-title">第六章 平安建设</div>
          <div class="item">
            <div class="item-t">第二十四条：</div>
            <div class="item-b">
              发扬主人翁精神，积极参与平安社区创建活动，积极参加平安志愿者、义工、义务巡逻等群防群治活动，共建共享平安建设成果。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二十五条：</div>
            <div class="item-b">
              支持配合和积极参与“网格化管理、组团式服务”，发现安全生产隐患、消防安全隐患、食品药品安全隐患、社会治安问题、环境污染问题、各类矛盾纠纷以及各种可疑人员、违法犯罪行为，及时告知物业管理人员、网格员或社区干部。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二十六条：</div>
            <div class="item-b">
              家庭有易肇事肇祸精神病人、刑释人员、社区服刑人员或误入邪教人员的，应积极配合社区党组织和社区居民委员会做好管理、教育和帮扶等工作。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二十七条：</div>
            <div class="item-b">
              提倡用协商办法解决各种矛盾纠纷，协商不成功的，可向社区、镇（街道）调委会申请调解，也可依法向人民法院起诉；依法理性表达利益诉求，不无理信访、越级信访和集体上访，不闹事滋事、扰乱社会秩序。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二十八条：</div>
            <div class="item-b">
              自觉遵法、学法、守法、用法，主动做好平安宣传，居民之间、家庭成员之间要互相提醒帮助、教育监督，不沾“黄毒赌”，不参加邪教组织，不参与传销活动，严防发生火灾、生产、交通、溺水等安全事故。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第二十九条：</div>
            <div class="item-b">
              自觉维护社区消防安全管理，爱护消防设施，不堵塞消防通道，学习和掌握消防安全知识和灭火扑救、自救逃生技能，防止火灾发生。
            </div>
          </div>
          <div class="h-title">第七章 民主参与</div>
          <div class="item">
            <div class="item-t">第三十条：</div>
            <div class="item-b">
              积极参与社区民主管理，珍惜自身民主权利，坚持从本社区公益事业发展和全体居民共同利益出发，认真提建议、作决策、选干部。积极参与党组织领导下的社区民主协商活动，拓宽居民言论渠道，合理表达个人诉求。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第三十一条：</div>
            <div class="item-b">
              严格遵守社区组织换届选举纪律，自觉抵制拉票贿选等违法违纪行为，不以个人关系亲疏、感情好恶、利益轻重为标准进行推荐和选举。推选奉公守法、品行良好、公道正派、廉洁自律、热心公益、具有一定文化水平和有参政议政能力的人员担任社区干部。涉及有“五不能”、“六不宜”情形的人员，不得作为社区组织成员候选人，如果当选，当选无效。
            </div>
          </div>
          <div class="h-title">第八章 奖惩措施</div>
          <div class="item">
            <div class="item-t">第三十二条：</div>
            <div class="item-b">
              社区居民委员会每年组织相关评比活动，经社区两委联席会议商议后，由社区居民委员会表彰奖励模范遵守本公约的家庭和社区居民。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第三十三条：</div>
            <div class="item-b">
              凡违反本公约的，经社区两委联席会议商议后，社区居民委员会对行为人酌情作出批评教育、公开通报、暂缓参加有关评选活动、恢复原状等相应处理决定；损害个人和集体利益的应照价赔偿，情节严重触犯法律的，移交相关部门，依照法律法规处理。
            </div>
          </div>
          <div class="h-title">第九章 附 则</div>
          <div class="item">
            <div class="item-t">第三十四条：</div>
            <div class="item-b">
              本公约由社区党组织和社区居民委员会负责解释。
            </div>
          </div>
          <div class="item">
            <div class="item-t">第三十五条：</div>
            <div class="item-b">
              本公约于2021年2月25日社区居民会议通过，自公布之日起施行。
            </div>
          </div>
        </div>
        <div class="body-b"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduce",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.treaty {
  min-height: 100vh;
  padding: 0 0 68px;
  background: #7a90f0;
  .topImg {
    height: 338px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .body {
    padding: 0 32px;
    margin-top: 0px;
    z-index: 99;
    position: relative;
    .body-center {
      background: #ffffff;
      border-radius: 16px;
      padding: 32px 54px 4px;
      .body-center-title {
        h2 {
          text-align: center;
          margin-bottom: 30px;
          font-size: 36px;
          font-weight: 700;
          color: #000;
          line-height: 44px;
        }
        h3 {
          text-align: center;
          margin-bottom: 48px;
          font-size: 32px;
          font-weight: 700;
          color: #000;
          line-height: 44px;
        }
      }
      .body-c {
        padding-bottom: 40px;
        .h-title {
          text-align: center;
          margin-bottom: 48px;
          font-size: 32px;
          font-weight: 700;
          color: #000;
          line-height: 44px;
        }
      }
      .item {
        margin-bottom: 48px;
        font-size: 32px;
        font-weight: 400;
        color: #646668;
        line-height: 44px;
        .item-t {
          font-size: 32px;
          font-weight: 700;
          color: #323334;
          line-height: 44px;
          margin-bottom: 8px;
        }
      }
      .title {
        font-size: 32px;
        text-align: center;
        font-weight: bold;
        color: #2992fd;
        margin-bottom: 22px;
        line-height: 44px;
        span {
          position: relative;
          &::before {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            left: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          &::after {
            content: "";
            position: absolute;
            width: 150px;
            height: 4px;
            background: linear-gradient(
              270deg,
              #2992fd 0%,
              rgba(41, 146, 253, 0) 100%
            );
            border-radius: 2px;
            right: -170px;
            top: 0;
            bottom: 0;
            margin: auto;
            transform: rotate(180deg);
          }
        }
      }
      .body-b {
        padding-bottom: 66px;
        p {
          font-size: 28px;
          font-weight: 400;
          color: #646668;
          line-height: 56px;
          b {
            font-weight: 700;
            color: #000;
          }
        }
      }
    }
  }
}
</style>
